import React, { useEffect } from "react";
import { gsap } from "gsap";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import "./App.css";

function App() {
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    });
  }, []);

  useEffect(() => {
    // 简单的淡入动画
    gsap.from(".text-content", {
      opacity: 0,
      y: 20,
      duration: 1.5,
      ease: "power2.out"
    });
  }, []);

  const options = {
    fullScreen: {
      enable: true,
      zIndex: 1
    },
    particles: {
      number: {
        value: 40,  // 减少粒子数量
        density: {
          enable: true,
          value_area: 800
        }
      },
      color: {
        value: "#ffffff"
      },
      shape: {
        type: "circle"
      },
      opacity: {
        value: 0.3,  // 降低不透明度
        random: false
      },
      size: {
        value: 2,
        random: true
      },
      line_linked: {
        enable: true,
        distance: 150,
        color: "#ffffff",
        opacity: 0.1,  // 降低连线不透明度
        width: 1
      },
      move: {
        enable: true,
        speed: 0.5,  // 降低移动速度
        direction: "none",
        random: true,
        straight: false,
        out_mode: "out",
        bounce: false
      }
    },
    interactivity: {
      detect_on: "canvas",
      events: {
        onhover: {
          enable: true,
          mode: "grab"
        },
        onclick: {
          enable: false  // 禁用点击效果
        },
        resize: true
      },
      modes: {
        grab: {
          distance: 140,
          line_linked: {
            opacity: 0.3  // 降低交互时连线的不透明度
          }
        }
      }
    },
    retina_detect: true,
    background: {
      color: "transparent",  // 改为透明，让CSS动画显示
      image: "",
      position: "50% 50%",
      repeat: "no-repeat",
      size: "cover"
    }
  };

  return (
    <div className="App">
      <Particles id="tsparticles" options={options} />
      <div className="content">
        <div>
          <h1 style={ {fontSize: "3rem",fontWeight: "300", letterSpacing:'2px'}}>星汉灿烂，若出其里。</h1>
          <p style={ {fontSize: "1.2rem",fontWeight: "300", letterSpacing:'1px'}}>装修中，敬请期待</p>
        </div>
      </div>
    </div>
  );
}

export default App;
